// this file will contain all the functions to handle database queries
import {db} from './firebaseApp';
import { collection, 
        addDoc, 
        setDoc, 
        doc, 
        arrayUnion,
        arrayRemove,
        updateDoc,
        getDoc, 
        getDocs,
        query,
        where,
        deleteField,
        deleteDoc,
        writeBatch,
        increment} from "firebase/firestore"; 
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getUniqueKeys, getColumnTypes, addToLocalStorage, updateLocalStorage, getUserList,addOrReplace, addToKey, getDateKey } from './helpers';

const batch = writeBatch(db);

const dataHandling = {
    initializeExtensionCollections: async (extension_name, email, created_uid, authToken) => {   
        const extensionData = {
            'extension name': extension_name,
            'status': 'Pending',
            'owner': email,
            'auth token': authToken,
            'users': 0, 
        }
        
        const docRef = await addDoc(collection(db, "extensions"), extensionData);
        // create the empty notificationsDelivered document
        const notificationsDocRef = doc(collection(db, `extensions/${docRef.id}/notificationsDelivered`), 'notificationsDelivered');
        await setDoc(notificationsDocRef, {});

        updateDoc(docRef, {
            'extension ID':docRef.id
        })
        // we also need to update create_uid's allowed extensions to have the new one docRef.id
        const userRef = doc(db, 'accounts', created_uid);
        updateDoc(userRef, {
            extensions: arrayUnion(docRef.id)
        });
        extensionData['id'] = docRef.id
        await dataHandling.loadExtensionIntoLocalMemory(docRef.id)
        return extensionData
    },

    initializeAccount: async(email, id) => {
        const docRef = doc(collection(db, 'accounts'), id);
        const expiryDate = new Date()
        expiryDate.setMonth(expiryDate.getMonth() + 1)
        const data = {
            email: email,
            extensions: [],
            subscriptionType: "free",
            freeCredits:100,
            freeCreditsExpiryDate:expiryDate,
            lastLogin: new Date()
        };
        setDoc(docRef, data)
        const newUser = {'new':true}
        localStorage.setItem('newUser', JSON.stringify(newUser));
    
    },

    updateLastLogin :async (user) => {
        const userRef = doc(db, 'accounts', user);
        updateDoc(userRef, {
            lastLogin: new Date()
        });
    },

    verifyAccessToken : async(email, accessToken) => {
        // the point of this method is to make sure the email and the accesstoken
        // are authorized to register as the payment method is not set up
        const tokens = collection(db, 'tokens');

        // Create a query with the specified conditions
        const q = query(
            tokens,
            where('email', '==', email),
            where('accessToken', '==', accessToken)
        );
        try {
            // Execute the query
            const querySnapshot = await getDocs(q);
            // Check if the document exists
            return !querySnapshot.empty;
        } catch (error) {
            console.error('Error checking document existence:', error);
            return false;
        }
    },

    fetchUserExtensions : async (extensionIds) =>{
        // we first fetch the list of extensions the user is allowed to view
        var tableRows = []
        for (const extId of extensionIds) {
            const extensionObj = JSON.parse(localStorage.getItem(extId))
            // remove the notifications, users etc... 
            delete extensionObj['user_rows']
            delete extensionObj['user_data_over_time']
            delete extensionObj['user_columns']
            delete extensionObj['notifications']
            delete extensionObj['user_column_types']
            delete extensionObj['notificationDelivered']
            delete extensionObj['last_notification_fetch']
            delete extensionObj['last_user_fetch']
            tableRows.push(extensionObj)
        }

        return tableRows
    },

    loadExtensionIntoLocalMemory : async (extensionID) => {
        const extensionRef = doc(db, 'extensions', extensionID);
        const extensionDocSnap = await getDoc(extensionRef);
        const extensionData =  extensionDocSnap.data()

        // for now we prefer to not fetch again these values in the db as this is run more often
        // we only reload Account info
        // const user_rows = await dataHandling.fetchUsersFromExtension(extensionID)
        // const user_data_over_time = 
        // extensionData['user_rows'] = user_rows
        // extensionData['user_columns']  = getUniqueKeys(extensionData['user_rows'])
        // extensionData['user_column_types'] = getColumnTypes(extensionData['user_rows'], extensionData['user_columns'])
        // extensionData['notifications'] = await dataHandling.fetchNotificationsFromExtension(extensionID)
        // extensionData['notificationDelivered'] = await dataHandling.fetchNotificationsDelivered(extensionID)
        // extensionData['last_notification_fetch'] = new Date()
        // extensionData['last_user_fetch'] = new Date()
        var extensionObj = localStorage.getItem(extensionID);
        extensionObj = JSON.parse(extensionObj)
        extensionObj = {...extensionObj, ...extensionData}
        localStorage.setItem(extensionID,JSON.stringify(extensionObj));

    },

    fetchUsersFromExtension : async (extensionID) => {
        try{
            var tableRows = []
            const usersRef = collection(db, 'extensions', extensionID, 'users');
            const documentsRetrieved = await getDocs(usersRef)
            documentsRetrieved.forEach((doc) => {
                let userData = doc.data()
                userData["id"] = doc.id
                for (const key in userData) {
                    const value = userData[key];
                    if(value === undefined || value === null){
                        continue
                    }
                    const milliseconds = value.seconds * 1000 + Math.floor(value.nanoseconds / 1e6);
                    if(!isNaN(milliseconds)){
                        const date = new Date(milliseconds)
                        // set the date to UTC + 1
                        // date.setHours(date.getHours() + 1)
                        userData[key] = date     
                    }
                   
                }
                tableRows.push(userData)
            })             
            return tableRows
        }
        catch(error){
            return []
        }
    },

    fetchTotalNumberUsers :  async (extensionID) => {
        // const querySnapshot = await getDocs(collection(db, 'extensions', extensionID, 'users'));
        // const count = querySnapshot.size;
        const user_rows = getUserList(extensionID)
        return user_rows.length;
    },

    fetchAllUserIDs : async (extensionID) => {
        const docIDs = [];
        const user_rows = getUserList(extensionID)
        user_rows.forEach(doc => {
            docIDs.push(doc.id)
        })

        return docIDs;
    },

    fetchAllUserRowsFromStorage : (extensionID) => {
        const extensionObj = JSON.parse(localStorage.getItem(extensionID))
        return extensionObj['user_rows']
    },

    fetchAllUserInfo: async (userID, saveToLocalStorage) => {
        const docRef = doc(db, 'accounts', userID);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const user_data = docSnap.data()
            if (saveToLocalStorage){
                user_data['lastFetch'] = new Date()
                localStorage.setItem('user', JSON.stringify(user_data));
                // and we also want to save in local storage the different extensions
                if(Object.keys(user_data).includes('extensions')){
                    for(var i = 0; i<user_data["extensions"].length; i=i+1){
                        await dataHandling.loadExtensionDataFromDB(user_data["extensions"][i])
                    }
                }
            }
            return user_data
        }
        return undefined

    },

    loadExtensionDataFromDB : async (extensionID) => {
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const extensionRef = doc(db, 'extensions', extensionID);
        const extensionDocSnap = await getDoc(extensionRef);
        const extensionData =  extensionDocSnap.data()
        const user_rows = await dataHandling.fetchUsersFromExtension(extensionID)
        extensionData['user_rows'] = user_rows
        extensionData['user_columns']  = getUniqueKeys(extensionData['user_rows'])
        extensionData['user_column_types'] = getColumnTypes(extensionData['user_rows'], extensionData['user_columns'])
        extensionData['user_data_over_time'] = await dataHandling.initializeUserDataOverTime(extensionID)
        extensionData['notifications'] = await dataHandling.fetchNotificationsFromExtension(extensionID)
        extensionData['notificationDelivered'] = await dataHandling.fetchNotificationsDelivered(extensionID)
        extensionData['last_notification_fetch'] = new Date()
        extensionData['last_user_fetch'] = new Date()
        extensionData['date_key_fetched'] = getDateKey(yesterday)
        extensionData['eventTriggers'] = await dataHandling.loadAllTriggerEventsFromDb(extensionID)
        localStorage.setItem(extensionID,JSON.stringify(extensionData));

    },

    storeNotificationInStorage : async (extensionID, min_update_date) => {
        const startTime = new Date();
        const lastUpdate = new Date(min_update_date);
        const q = query(collection(db, `extensions/${extensionID}/notifications`),
                                where('last update', '>', lastUpdate));
        const querySnapshot = await getDocs(q)
        var extensionObj = localStorage.getItem(extensionID);
        extensionObj = JSON.parse(extensionObj)
        querySnapshot.forEach((doc) => {
            let notificationData = doc.data()
            notificationData["id"] = doc.id
            for (const key in notificationData) {
                const value = notificationData[key];
                if(value === undefined || value === null){
                    continue
                }
                const milliseconds = value.seconds * 1000 + Math.floor(value.nanoseconds / 1e6);
                if(!isNaN(milliseconds)){
                    const date = new Date(milliseconds)
                    // set the date to UTC + 1
                    // date.setHours(date.getHours() + 1)
                    notificationData[key] = date     
                }
            }
            addOrReplace(extensionObj['notifications'], notificationData)

        });
        extensionObj['notificationDelivered'] = await dataHandling.fetchNotificationsDelivered(extensionID)
        extensionObj['last_notification_fetch'] = new Date()
        localStorage.setItem(extensionID,JSON.stringify(extensionObj));

        const endTime = new Date();
        const executionTime = endTime - startTime;
    },

    storeUsersInStorage : async (extensionID, min_update_date) => {
        const startTime = new Date();

        const lastLoginDate = new Date(min_update_date);
        const q = query(collection(db, `extensions/${extensionID}/users`),
                                where('last login', '>', lastLoginDate));
        const querySnapshot = await getDocs(q)
        var extensionObj = localStorage.getItem(extensionID);
        extensionObj = JSON.parse(extensionObj)
        querySnapshot.forEach((doc) => {
            let userData = doc.data()
            userData["id"] = doc.id
            for (const key in userData) {
                const value = userData[key];
                if(value === undefined || value === null){
                    continue
                }
                const milliseconds = value.seconds * 1000 + Math.floor(value.nanoseconds / 1e6);
                if(!isNaN(milliseconds)){
                    const date = new Date(milliseconds)
                    // set the date to UTC + 1
                    // date.setHours(date.getHours() + 1)
                    userData[key] = date     
                }
            }
            addOrReplace(extensionObj['user_rows'], userData)
        });
        extensionObj['user_columns']  = getUniqueKeys(extensionObj['user_rows'])
        extensionObj['user_column_types'] = getColumnTypes(extensionObj['user_rows'], extensionObj['user_columns'])
        extensionObj['last_user_fetch'] = new Date()
        localStorage.setItem(extensionID,JSON.stringify(extensionObj));

        const endTime = new Date();
        const executionTime = endTime - startTime;
    },

    saveProfilePhoto : async (file, userID) => {
        // function to stave the profile photo into storage
        const storage = getStorage();
        const storageRef = ref(storage, 'profilePhotos/' + userID);
        
        const snapshot = await uploadBytes(storageRef, file)
        // File uploaded successfully, now get the URL
        const photoURL = await getDownloadURL(snapshot.ref)
        
        return photoURL
    },

    createNotification : async (extensionID, recipients, notificationObject) => {
        // here we want to create the notifications events
        
        // before we create the notification we first want to update the user data in local storage
        var extensionObj = JSON.parse(localStorage.getItem(extensionID))
        var date_user_fetched = extensionObj['last_user_fetch']
        await dataHandling.storeUsersInStorage(extensionID, date_user_fetched)

        
        // create the entry in firestore
        var notificationRef = await addDoc(collection(db, "extensions",extensionID, 'notifications'), notificationObject);
        const new_notification_ID = notificationRef.id
        var notificationsToUpdate = {}
        var notificationRef;
        var userList = getUserList(extensionID).filter((user) => recipients.includes(user.id))

        // update for each recipient the next notification in firebase
        // and check if they had a pending notification and cancel that one
        for (const userData of userList) {
            const currentNotification = userData['next notification']
            if (currentNotification !== null && currentNotification !== undefined) {
                notificationsToUpdate[currentNotification] = (notificationsToUpdate[currentNotification] ?? 0) + 1;
            }
            const docRef = doc(db, 'extensions', extensionID, 'users', userData.id);
            updateDoc(docRef, {
                "next notification": new_notification_ID
            });
        }

        // update for each recipient the value of the next notification
        updateLocalStorage(extensionID, 'user_rows', recipients, undefined, {"next notification": new_notification_ID},'update')

        for (const notifID in notificationsToUpdate) {
            // we want to update each notification with the number 
            // of users that will not be receiving it
            notificationRef = doc(db, 'extensions', extensionID, 'notifications', notifID);
            updateDoc(notificationRef, {
                "canceled": increment(notificationsToUpdate[notifID]),
                'last update':new Date()
            });
            updateLocalStorage(extensionID, 'notifications', [notifID],'canceled',notificationsToUpdate[notifID],'increment')
        }

        // finally we save the new notif in local storage
        notificationObject['id'] = new_notification_ID
        addToLocalStorage(extensionID, 'notifications', undefined, notificationObject)

        return new_notification_ID
    },

    fetchNotificationsFromExtension : async(extensionID) => {
        try{
            var tableRows = []
            const notificationsRef = collection(db, 'extensions', extensionID, 'notifications');
            const documentsRetrieved = await getDocs(notificationsRef)
            documentsRetrieved.forEach((doc) => {
                let notificationData = doc.data()
                notificationData["id"] = doc.id
                for (const key in notificationData) {
                    const value = notificationData[key];
                    if(value === undefined || value === null){
                        continue
                    }
                    const milliseconds = value.seconds * 1000 + Math.floor(value.nanoseconds / 1e6);
                    if(!isNaN(milliseconds)){
                        const date = new Date(milliseconds)
                        // set the date to UTC + 1
                        // date.setHours(date.getHours() + 1)
                        notificationData[key] = date     
                    }
                }
                tableRows.push(notificationData)
            });
            return tableRows
        }
        catch(error){
            return []
        }
    },

    fetchNotificationsDelivered : async (extensionID) => {
        const notificationsDeliveredRef = doc(db, 'extensions', extensionID, 'notificationsDelivered','notificationsDelivered');
        const docSnap = await getDoc(notificationsDeliveredRef)
        if (docSnap.exists()) {
            return docSnap.data()
        }
        return {}
    },

    fetchNotificationInformation : async (extensionID, notificationID) => {
        const notificationsRef = doc(db, 'extensions', extensionID, 'notifications', notificationID);
        const docSnap = await getDoc(notificationsRef)
        if (docSnap.exists()) {
            return docSnap.data()
        }
        return{}
    },

    cancelNotification : async (extensionID, notificationID) => {
        // set the active value of the notification to false
        const notificationsRef = doc(db, 'extensions', extensionID, 'notifications', notificationID);
        updateDoc(notificationsRef, {
            active: false,
            'last update':new Date()
        });
        
        updateLocalStorage(extensionID, 'notifications', [notificationID], undefined, {
            'active' : false
        }, 'update')

        // We query the users who have that notification as their next one
        // and simply remove it.
        const user_query = query(collection(db, `extensions/${extensionID}/users`),
                                where('next notification', '==', notificationID));
        const snapshot_docs = await getDocs(user_query)
        var ids_to_update = []
        snapshot_docs.forEach(async (doc) => {
            const userRef = doc.ref;
            updateDoc(userRef, {
              'next notification': null,
            }); 
            ids_to_update.push(doc.id)
        })     
        updateLocalStorage(extensionID, 'user_rows', ids_to_update, undefined,  {'next notification': null }, 'update')

    },

    resendNotification : async (extensionID, notificationID) => {
        const notificationsRef = doc(db, 'extensions', extensionID, 'notifications', notificationID);
        const docSnap = await getDoc(notificationsRef);
        const notif_data = docSnap.data()
        updateDoc(notificationsRef, {
            'status': 'resent',
            'title' : `${notif_data.title}`,
            'active' : false,
            'last update': new Date(),
        });

        updateLocalStorage(extensionID, 'notifications', [notificationID], undefined, {
            'status': 'resent',
            'title' : `${notif_data.title}`,
            'active' : false,
        }, 'update')

        delete notif_data["Read By"]
        let recipients = []
        if(notif_data['selection type'] === 'All Users'){
            recipients = await dataHandling.fetchAllUserIDs(extensionID)
        }
        notif_data['target users'] = recipients.length
        notif_data['delivered'] = 0
        notif_data['canceled'] = 0
        notif_data['active'] = true
        notif_data['date created'] = new Date()
        notif_data['last update'] = new Date()
        notif_data['scheduled for'] = null
        notif_data['read by'] = []
        notif_data['status'] = 'Pending'
        notif_data['scheduled'] = false
        await dataHandling.createNotification(extensionID, recipients, notif_data)
    },

    deleteExtension : async (extensionID) => {
        const collectionRef = collection(db, 'accounts');
        // Create a query that filters documents where the array field contains the target value
        const queryRef = query(collectionRef, where('extensions', 'array-contains', extensionID));
        try {
            const snapshot = await getDocs(queryRef);
            snapshot.forEach(async (doc) => {
              // Access each document here
              await updateDoc(doc.ref, {
                'extensions': arrayRemove(extensionID)
              });

            });
          } catch (error) {
            console.error('Error querying documents:', error);
          }
    },

    getStripeSessionId : async (userId) => {
        const docRef = doc(collection(db, 'accounts'), userId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            var doc_data = docSnap.data()
            return doc_data['sessionId']
        }
        return undefined
    },

    removeSessionId : async (userId) => {
        const docRef = doc(collection(db, 'accounts'), userId);
        updateDoc(docRef, {
            'sessionId':null
        })

    },

    retrieveCustomerId:  async (userId) => {
        const docRef = doc(collection(db, 'accounts'), userId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            var doc_data = docSnap.data()
            return doc_data['stripeCustomerId']
        }
        return undefined
    },

    loadAccountInfo : async (userId) => {
        const docRef = doc(db, 'accounts', userId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const user_data = docSnap.data()
            user_data['lastFetch'] = new Date()
            localStorage.setItem('user', JSON.stringify(user_data));
            // load extension info as well
            if(Object.keys(user_data).includes('extensions')){
                for(var extID of user_data['extensions']){
                    await dataHandling.loadExtensionIntoLocalMemory(extID)
                }
            }
            return user_data
        }
        return undefined

    },

    initializeUserDataOverTime : async (extensionID) => {
        var userAnalyticsOverTime = {}
        const end_date = new Date()
        end_date.setHours(0,0,0,0)
        const q = query(collection(db, 'extensions', extensionID, 'userAnalytics'),  
                        where('last login', '<=', end_date)
        );
        const documents = await getDocs(q)

        documents.forEach((doc) => {
            const userID = doc.id.split('_')[0]
            const userData = doc.data()
            for (const key in userData) {
                const value = userData[key];
                if(value === undefined || value === null){
                    continue
                }
                const milliseconds = value.seconds * 1000 + Math.floor(value.nanoseconds / 1e6);
                if(!isNaN(milliseconds)){
                    const date = new Date(milliseconds)
                    // set the date to UTC + 1
                    // date.setHours(date.getHours() + 1)
                    userData[key] = date     
                }
               
            }
            addToKey(userID, userAnalyticsOverTime, userData)
        })
        return userAnalyticsOverTime
    },

    updateUserOverTimeData : async (extensionID, start_date_key) => {
        // this function serves to do the following: 
        // fetch the data from the users in the userAnalytics collection
        // that are between start_date and yesterday. 
        // these are all the 'overTime' data that is not yet present in the local storage
        var extensionObj = localStorage.getItem(extensionID);
        extensionObj = JSON.parse(extensionObj)

        const [year, month, day] = start_date_key.split('-');
        const start_date = new Date(year, month - 1, day);
        const end_date = new Date()
        end_date.setHours(0, 0, 0, 0);
        const q = query(collection(db, 'extensions', extensionID, 'userAnalytics'),  
                        where('last login', '>=', start_date),
                        where('last login', '<=', end_date)
                  );

        const documents = await getDocs(q)
        documents.forEach((doc) => {
            const userID = doc.id.split('_')[0]
            const userData = doc.data()
            for (const key in userData) {
                const value = userData[key];
                if(value === undefined || value === null){
                    continue
                }
                const milliseconds = value.seconds * 1000 + Math.floor(value.nanoseconds / 1e6);
                if(!isNaN(milliseconds)){
                    const date = new Date(milliseconds)
                    // set the date to UTC + 1
                    // date.setHours(date.getHours() + 1)
                    userData[key] = date     
                }
               
            }
            addToKey(userID, extensionObj['user_data_over_time'], userData)
        })
        end_date.setDate(end_date.getDate() - 1)
        extensionObj['date_key_fetched'] = getDateKey(end_date)
        localStorage.setItem(extensionID,JSON.stringify(extensionObj));
        
        return extensionObj

    },

    saveNewEventTrigger : async (extensionID, obj) => {
        // we save the event info into firestore database
        var eventTriggerRef = await addDoc(collection(db, "extensions",extensionID, 'eventTriggers'), obj);
        // and we also want to update the local storage data
        var extensionObj = localStorage.getItem(extensionID);
        extensionObj = JSON.parse(extensionObj)
        obj['id'] = eventTriggerRef.id
        if('eventTriggers' in extensionObj){
            extensionObj['eventTriggers'].push(obj)
        }
        else{
            extensionObj['eventTriggers'] = [obj]
        }
        localStorage.setItem(extensionID,JSON.stringify(extensionObj));

        return eventTriggerRef.id
    },

    fetchExisitingTriggerEvents : (extensionID) => {
        var extensionObj = localStorage.getItem(extensionID);
        extensionObj = JSON.parse(extensionObj)
        return extensionObj['eventTriggers'] || []
    },

    loadAllTriggerEventsFromDb : async (extensionID) => {
        const eventRef = collection(db, 'extensions', extensionID, 'eventTriggers');
        const documentsRetrieved = await getDocs(eventRef)
        const allEvents = []
        documentsRetrieved.forEach((doc) => {
            let eventData = doc.data()
            eventData["id"] = doc.id
            allEvents.push(eventData)
        })
        return allEvents
    },

    deleteEventTrigger : async (extensionID, eventID) => {
        const docRef = doc(db, 'extensions', extensionID, 'eventTriggers', eventID);
        await deleteDoc(docRef);
        var extensionObj = localStorage.getItem(extensionID);
        extensionObj = JSON.parse(extensionObj)
        extensionObj['eventTriggers'] = extensionObj['eventTriggers'].filter((el) => el.id != eventID)
        localStorage.setItem(extensionID,JSON.stringify(extensionObj));

    },

    pauseEventTriggerNotification : async (extensionID, notificationID) => {
        const notificationsRef = doc(db, 'extensions', extensionID, 'notifications', notificationID);
        console.log("we are here to set the value to false")
        console.log(notificationID)
        console.log(notificationsRef)
        await updateDoc(notificationsRef, {
            active: false,
        });
        updateLocalStorage(extensionID, 'notifications', [notificationID], undefined, {
            'active' : false
        }, 'update')

    },

    restartEventTriggerNotification : async (extensionID, notificationID) => {
        const notificationsRef = doc(db, 'extensions', extensionID, 'notifications', notificationID);
        await updateDoc(notificationsRef, {
            active: true,
        });
        updateLocalStorage(extensionID, 'notifications', [notificationID], undefined, {
            'active' : true
        }, 'update')

    }

};

export default dataHandling;
