import React, {useEffect, useState} from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getAuth, 
    onAuthStateChanged } from "firebase/auth";

const ProtectedRoute = ({ component: Component, componentProps, ...rest }) => {
    const [loggedIn, setLoggedIn] = useState(null)
    
    
    useEffect(()=>{
        async function handleAuth() {
            const auth = getAuth()
            onAuthStateChanged(auth, (user) => {
                if (user) {
                  // User is signed in
                  setLoggedIn(true)
                } else {
                  // No user signed in
                setLoggedIn(false)
                }
              });
          }
          handleAuth();
    }, []);

    return (
        <>
        {loggedIn != null &&
            <Route
                {...rest}
                render={(props) =>
                    loggedIn ? (
                        <Component {...props} {...componentProps} />
                    ) : (
                        <Redirect to="/user-pages/login" />
                    )
            }
            />
        }
        
        </>
       
        
    );
};

export default ProtectedRoute;