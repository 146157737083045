import { getAuth, signOut, 
        signInWithEmailAndPassword,
        createUserWithEmailAndPassword,
        onAuthStateChanged,
        signInWithCustomToken,
        updateProfile, 
        updatePassword,
       sendPasswordResetEmail,
       reauthenticateWithCredential,
       EmailAuthProvider} from "firebase/auth";

import {firebaseApp} from '../services/firebaseApp';
import dataHandling from "./dataHandling";

const auth = getAuth()
export const signOutUser = async () => {
    try {
      await signOut(auth);
      // Sign-out successful.
      return { success: true };
    } catch (error) {
      // An error happened.
      return { success: false, error: error.message };
    }
};

export const signInUser = async (email, password) => {
  try{
    const output = await signInWithEmailAndPassword(auth, email, password)
    return { success: true };
  } catch (error){
    return { success: false, error: error.message };
  }
}

export const registerUser = async (email, password) => {
  try {
    await createUserWithEmailAndPassword(auth, email, password);
    return { success: true };
  } catch (error) {
    return { success: false, error: error.message };
  }
}

export const updateLastLogin = async (user) => {
  try{
    dataHandling.updateLastLogin(user)
  } catch(error) {

  }
}

export const getCurrentUserId = () => {
  return auth.currentUser.uid
}
export const getCurrentEmail = () => {
  return auth.currentUser.email
}

export const getCurrentProfilePicture = () => {
  return auth.currentUser.photoURL
}

export const saveProfilePicture = async (file, uploadComplete) => {

    var user = auth.currentUser;

    const newPhotoURL = await dataHandling.saveProfilePhoto(file, user.uid)
    if(newPhotoURL){
      updateProfile(user, {
        photoURL: newPhotoURL
      }); 
    }
    uploadComplete(newPhotoURL)
         
}

export const firebaseChangePassword = async (newPassword, existingPassword) => {
  var user = auth.currentUser;
  const credential = EmailAuthProvider.credential(
    user.email,
    existingPassword
  )
  try{
    await reauthenticateWithCredential(user, credential)
    await updatePassword(user, newPassword)
    return {success:true}
  }
  catch(error){
    return {success:false, errorMessage:error}
  }
}
 



export const sendFirebasePasswordResetEmail = async (email) => {
  sendPasswordResetEmail(auth, email)
    .then(() => {
      console.log("EMAIL SENT!")
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorMessage)
      console.log(error)
      // ..
  });
}