import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoutes'
import Spinner from '../app/shared/Spinner';

const NotificationDashboard = lazy(() => import('./dashboard/NotificationDashboard'));
const Notifications = lazy(() => import('./notifications/Notifications'));
const ExtensionUsers = lazy(() => import('./extension-users/Users'));
const MyAccount = lazy(() => import('./user-pages/Account'));
const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const Welcome = lazy(() => import('./user-pages/Welcome'));
const Subscription = lazy(()=> import('./user-pages/SubscriptionPage'))
const PaymentSuccess = lazy(() => import('./user-pages/PaymentSuccess'))
const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));

const Login = lazy(() => import('./user-pages/Login'));
const Register1 = lazy(() => import('./user-pages/Register'));
const ResetPassword = lazy(() => import('./user-pages/ResetPassword'));

export default function AppRoutes(props) {

  return (
      <Suspense fallback={<Spinner/>}>
        <Switch>
          {/* <ProtectedRoute exact path="/dashboard" component={ Dashboard } /> */}
          <ProtectedRoute exact path="/notification_dashboard" component={ NotificationDashboard } />
          <ProtectedRoute exact path="/notifications" component={ Notifications } />
          <ProtectedRoute exact path="/extension_users" component={ ExtensionUsers } />
          <ProtectedRoute exact path="/my-account" component={ MyAccount } componentProps={{updatePhoto : props.updatePhoto}}/>
          <ProtectedRoute exact path="/welcome-page" component={ Welcome }/>
          <ProtectedRoute exact path="/subscription" component={Subscription}/>
          <ProtectedRoute exact path="/payment-success" component={PaymentSuccess}/>


          {/* <ProtectedRoute path="/basic-ui/buttons" component={ Buttons } />
          <ProtectedRoute path="/basic-ui/dropdowns" component={ Dropdowns } />

          <ProtectedRoute path="/form-Elements/basic-elements" component={ BasicElements } />

          <ProtectedRoute path="/tables/basic-table" component={ BasicTable } />

          <ProtectedRoute path="/icons/mdi" component={ Mdi } />

          <ProtectedRoute path="/charts/chart-js" component={ ChartJs } /> */}


          <Route path="/user-pages/login" component={ Login } />
          <Route path="/user-pages/register" component={ Register1 } />
          <Route path="/user-pages/reset-password" component={ ResetPassword } />
          <Route path="/error-pages/error-404" component={ Error404 } />
          <Route path="/error-pages/error-500" component={ Error500 } />


          <Redirect to="/user-pages/login"/>
        </Switch>
      </Suspense>
    );
}
