import { v4 as uuidv4 } from 'uuid';


class Lock {
  constructor() {
    this.isLocked = false;
    this.queue = [];
  }

  async acquire() {
    return new Promise((resolve) => {
      if (!this.isLocked) {
        this.isLocked = true;
        resolve();
      } else {
        this.queue.push(resolve);
      }
    });
  }

  release() {
    if (this.queue.length > 0) {
      const nextResolver = this.queue.shift();
      nextResolver();
    } else {
      this.isLocked = false;
    }
  }
}


// Generate a UUID
export const generatedUUID = () => {
  return uuidv4();
} 

export const generateToken = (length) => {
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let token = '';
  for (let i = 0; i < length; i++) {
    token += charset.charAt(Math.floor(Math.random() * charset.length));
  }
  return token;
}

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const customSort = (strings, orderList) => {
  // Create a map to store the index of each string in the orderList
  const orderMap = new Map();
  orderList.forEach((str, index) => {
    orderMap.set(str, index);
  });

  // Custom sort function
  strings.sort((a, b) => {
    const indexA = orderMap.get(a);
    const indexB = orderMap.get(b);

    // If both strings are in the orderList, sort based on their order
    if (indexA !== undefined && indexB !== undefined) {
      return indexA - indexB;
    }

    // If only one string is in the orderList, prioritize it
    if (indexA !== undefined) {
      return -1;
    }
    if (indexB !== undefined) {
      return 1;
    }

    // If neither string is in the orderList, sort alphabetically
    return a.localeCompare(b);
  });

  return strings;
}

export const cutomSortFunction = (a, b, order, dataField, rowA, rowB) => {
  var final_a = a
  var final_b = b
  try{
    final_a = new Date(a) //.seconds * 1000 + Math.floor(a.nanoseconds / 1e6))
    final_b = new Date(b)//.seconds * 1000 + Math.floor(b.nanoseconds / 1e6))
  }
  catch{
  }
 
  if (order === 'asc') {
    return final_a - final_b
  }
  return final_b - final_a;; // desc
}

export const getUniqueKeys = (list_of_rows) => {
  const uniqueKeysSet = new Set();

  list_of_rows.forEach(obj => {
    Object.keys(obj).forEach(key => {
      uniqueKeysSet.add(key);
    });
  });

  return Array.from(uniqueKeysSet);
}

export const getColumnTypes = (list_of_rows, column_names) => {
    var types = {}
    for (const columnName of column_names) {
      for (const doc of list_of_rows) {
        if (columnName in doc) {
          types[columnName.toUpperCase().replace(' ', '_')] = getTypeOfValue(doc[columnName])
          break;
        }
      }
    }
    return types
}

const getTypeOfValue = (value) => {
  const objectType = typeof value
  if (value instanceof Date) {
    return 'date'
  }
  return objectType
}

export const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export const createDateFromYYYYMMDD = (dateString) => {
  const [year, month, day] = dateString.split('-');
  return new Date(year, month - 1, day); // Note: Months are zero-based
}

export const convertFirestoreTimestamp = (timestamp) =>  {
  return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6);
}

export const getPreviousDate = (date, timeInterval) => {
  var newDate = new Date(date)
  if(timeInterval === "1D"){
    newDate.setDate(newDate.getDate() - 1)
  }
  else if(timeInterval === '1W'){
    newDate.setDate(newDate.getDate() - 7)
  }
  else if(timeInterval === '15D'){
    newDate.setDate(newDate.getDate() - 15)
  }
  else if(timeInterval === '1M'){
    newDate.setMonth(newDate.getMonth() - 1)
  }
  else if(timeInterval === '3M'){
    newDate.setFullYear(newDate.getFullYear() - 1)
  }
  else{

  }
  
  return newDate
}

export const getNextDate = (date, timeInterval) => {
  var newDate = new Date(date)
  if(timeInterval === "Daily"){
    newDate.setDate(newDate.getDate() + 1)
  }
  else if(timeInterval === 'Weekly'){
    newDate.setDate(newDate.getDate() + 7)
  }
  else{
    newDate.setMonth(newDate.getMonth() + 1)
  }
  return newDate
}



// local storage helpers
export const addToLocalStorage =  (extensionID, key, subkey, object) => {
  // we add it to the local storage
  const localStorageData = localStorage.getItem(extensionID);
  const extensionObj = JSON.parse(localStorageData)
  var objectToUpdate = extensionObj[key]
  if(subkey != undefined){
    objectToUpdate = objectToUpdate[subkey]
  }
  objectToUpdate.push(object)
  localStorage.setItem(extensionID, JSON.stringify(extensionObj));
}

export const updateLocalStorage = (extensionID, key, ids, sub_key, value, type) => {
  const localStorageData = localStorage.getItem(extensionID);
  const extensionObj = JSON.parse(localStorageData)
  const objects = extensionObj[key]
  objects.forEach(obj => {
    if(ids.includes(obj.id)){
      if(type === 'increment'){
        obj[sub_key] += value
      }
      else if(type === 'update'){
        // then the value is a dictionary of values we need to update
        Object.assign(obj, value);
      }
    }
  });  
  localStorage.setItem(extensionID, JSON.stringify(extensionObj));
}

export function isLocalStorageKeyExists(key) {
  return localStorage.getItem(key) !== null;
}

export function deleteLocalStorageKey(key) {
  localStorage.removeItem(key);
}

export const getUserList = (extensionID) => {
  const localStorageData = localStorage.getItem(extensionID);
  const extensionObj = JSON.parse(localStorageData)
  return extensionObj['user_rows']
}

export const getNotificationsList = async (extensionID) => {
  const localStorageData = localStorage.getItem(extensionID);
  const extensionObj = JSON.parse(localStorageData)
  return extensionObj['notifications']
}

export const addOrReplace = (rows, new_row) => {
  const existingUserIndex = rows.findIndex((row) => row.id === new_row.id);
  // If the user is in the list, replace the data
  if (existingUserIndex !== -1) {
    rows[existingUserIndex] = new_row
  } else {
    // If the user is not in the list, add a new entry
    rows.push(new_row);
  }

}

export const addToKey = (key, obj, new_obj) => {
  if(key in obj){
    obj[key].push(new_obj)
  } 
  else{
    obj[key] = [new_obj]
  }
}


export function isFiveMinutesAgo(targetDate) {
  var targetDateObject = new Date(targetDate);

  // Get the current date and time
  var currentDate = new Date();

  // Calculate the date and time 5 minutes ago
  var fiveMinutesAgo = new Date(currentDate - 5 * 60 * 1000);


  // Compare the target date with the calculated date 5 minutes ago
  return targetDateObject < fiveMinutesAgo;
}

export function isTwoMinutesAgo(targetDate) {
  var targetDateObject = new Date(targetDate);

  // Get the current date and time
  var currentDate = new Date();

  // Calculate the date and time 5 minutes ago
  var fiveMinutesAgo = new Date(currentDate - 2 * 60 * 1000);


  // Compare the target date with the calculated date 5 minutes ago
  return targetDateObject < fiveMinutesAgo;
}

export function isThirtyMinutesAgo(targetDate) {
  var targetDateObject = new Date(targetDate);

  // Get the current date and time
  var currentDate = new Date();

  // Calculate the date and time 30 minutes ago
  var thirtyMinutesAgo = new Date(currentDate - 1 * 60 * 1000);

  // Compare the target date with the calculated date 30 minutes ago
  return targetDateObject < thirtyMinutesAgo;
}

export function formatDateTime(dateString) {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  };
  const date = new Date(dateString)

  const formattedDate = date.toLocaleDateString('en-US', options);

  return `${formattedDate}`;
}

export function isValidDateFormat(str) {
  const dateFormatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
  return dateFormatRegex.test(str);
}

export function getDateKey(date){
  // Identify the relevant date keys based on the interval
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
  const day = date.getDate().toString().padStart(2, '0');
 
  // Create the formatted date string
  const dateKey = `${year}-${month}-${day}`;
  return dateKey
 }

 export function getMonthlyDateKey(date){
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
 
  // Create the formatted date string
  const dateKey = `${year}-${month}`;
  return dateKey
 }
 

export function computeMonthlySent(dict_data) {
  // function to compute the number of notifications sent in the current month
  var currentDate = new Date()
  currentDate.setDate(1);
  var currentMonth = currentDate.getMonth()
  var date_key;
  var total = 0
  while(currentDate.getMonth() === currentMonth){
    date_key = getDateKey(currentDate)
    if(dict_data.hasOwnProperty(date_key)){
      total += dict_data[date_key].length
    }
    currentDate.setDate(currentDate.getDate() + 1)
  }
  return total


}

export function formatUrl(url) {
  if(url === ""){
    return url
  }
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
        url = "https://" + url;
  } 
  return url
}


export function handleTimeZoneEffect(date){
  // for now the dates are 1 hour ahead of the actual time so we bring them back to the UTC
  // date.setHours(date.getHours() - 1)
  return date
}