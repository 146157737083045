import React, { Component } from 'react'

export default function Spinner(props){
    return (
      <div>
        <div className="spinner-wrapper" id={props.id}>
          <div className="donut"></div>
        </div>
      </div>
    )
}