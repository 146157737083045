import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Dropdown, Collapse, Nav } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import styled from "@emotion/styled";
import { signOutUser } from '../services/authServices';
import { propTypes } from 'react-bootstrap/esm/Image';


const StyledLogoOpen = styled.div`
  width: 120px;
  min-width: 45px;
  height: 45px;
  min-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: white;
  font-size: 30px;
  font-weight: 700;
  margin-left: auto;
  margin-right: auto;
`;

const StyledLogoClosed = styled.div`
  width: 45px;
  min-width: 45px;
  height: 45px;
  min-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: white;
  font-size: 30px;
  font-weight: 700;
  background-color: #009fdb;
  background: linear-gradient(45deg, rgb(21 87 205) 0%, rgb(90 225 255) 100%);
  margin-left: auto;
  margin-right: auto;
`;



const Sidebar = ({ history, location, NavbarIsOpen }) => {
  const [menuState, setMenuState] = useState({});
  
  const toggleMenuState = (menuStateKey) => {
    if (menuState[menuStateKey]) {
      setMenuState({ ...menuState, [menuStateKey]: false });
    } else if (Object.keys(menuState).length === 0) {
      setMenuState({ [menuStateKey]: true });
    } else {
      Object.keys(menuState).forEach((i) => {
        setMenuState((prevState) => ({ ...prevState, [i]: false }));
      });
      setMenuState({ ...menuState, [menuStateKey]: true });
    }
  };

  const isPathActive = (path) => location.pathname.startsWith(path);

  const handleSignOut = async (evt) => {
    evt.preventDefault();
    // handle sign out logic
    const response = await signOutUser()
    if (response.success){
      history.push('user-pages/login')
  }
  };

  const onRouteChanged = () => {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(menuState).forEach((i) => {
      setMenuState((prevState) => ({ ...prevState, [i]: false }));
    });

    const dropdownPaths = [
      // Dropdown paths data
    ];

    dropdownPaths.forEach((obj) => {
      if (isPathActive(obj.path)) {
        setMenuState((prevState) => ({ ...prevState, [obj.state]: true }));
      }
    });
  };

  useEffect(() => {
    onRouteChanged();
  }, [location.pathname]);

  useEffect(() => {
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      el.addEventListener('mouseover', () => {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', () => {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }, []);

  return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
          { NavbarIsOpen
            ? (
              <div className="brand-logo-sidebar-open">
                <img src={require('../../assets/images/logo_with_name_white.png')} alt="logo" />
              </div>            ) : (
              <div className="brand-logo-sidebar-closed">
                <img src={require('../../assets/images/logo_white.png')} alt="logo" />
              </div>
            )
          }
          
          {/* <a className="sidebar-brand brand-logo" href="index.html"><img src={""} alt="logo" /></a> */}
          {/* <a className="sidebar-brand brand-logo-mini pt-3" href="index.html"><img src={""} alt="logo" /></a> */}
        </div>
        <br/>
        <ul className="nav">

          {/* BEGIN CHANGES  */}
          <li className={ isPathActive('/notification_dashboard') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/notification_dashboard">
              <i className="mdi mdi-television menu-icon"></i>
              <span className="menu-title"><Trans>Dashboard</Trans></span>
            </Link>
          </li>
          <li className={ isPathActive('/notifications') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/notifications">
              <i className="mdi mdi-format-list-bulleted menu-icon"></i>
              <span className="menu-title"><Trans>Notifications</Trans></span>
            </Link>
            {/* <div className={ this.state.notificationMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('notificationMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
              <span className="menu-title"><Trans>Notifications</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.notificationMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/notifications') ? 'nav-link active' : 'nav-link' } to="/notifications"><Trans>Grouped</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/notifications/individual') ? 'nav-link active' : 'nav-link' } to="/notifications/individual"><Trans>Individual</Trans></Link></li>
              </ul>
            </Collapse> */}
          </li>
          <li className={ isPathActive('/extension_users') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/extension_users">
              <i className="mdi mdi-account-multiple menu-icon"></i>
              <span className="menu-title"><Trans>Users</Trans></span>
            </Link>
          </li>
          <li className={ isPathActive('/my-account') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/my-account">
              <i className="mdi mdi-account-box-outline menu-icon"></i>
              <span className="menu-title"><Trans>My Account</Trans></span>
            </Link>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="https://www.syndeo.cloud/home/#resources" rel="noopener noreferrer" target="_blank">
              <i className="mdi mdi-file-outline menu-icon"></i>
              <span className="menu-title"><Trans>Documentation</Trans></span>
            </a>
          </li>

          {/* END CHANGES */}

{/*           
           <li className={ isPathActive('/dashboard') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard">
              <i className="mdi mdi-television menu-icon"></i>
              <span className="menu-title"><Trans>Dashboard</Trans></span>
            </Link>
          </li>
          <li className={ isPathActive('/basic-ui') ? 'nav-item active' : 'nav-item' }>
            <div className={ menuState.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('basicUiMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
              <span className="menu-title"><Trans>Basic UI Elements</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ menuState.basicUiMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ isPathActive('/basic-ui/buttons') ? 'nav-link active' : 'nav-link' } to="/basic-ui/buttons"><Trans>Buttons</Trans></Link></li>
                <li className="nav-item"> <Link className={ isPathActive('/basic-ui/dropdowns') ? 'nav-link active' : 'nav-link' } to="/basic-ui/dropdowns"><Trans>Dropdowns</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ isPathActive('/form-elements') ? 'nav-item active' : 'nav-item' }>
            <div className={ menuState.formElementsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('formElementsMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-format-list-bulleted menu-icon"></i>
              <span className="menu-title"><Trans>Form Elements</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ menuState.formElementsMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ isPathActive('/form-elements/basic-elements') ? 'nav-link active' : 'nav-link' } to="/form-elements/basic-elements"><Trans>Basic Elements</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ isPathActive('/tables') ? 'nav-item active' : 'nav-item' }>
            <div className={ menuState.tablesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('tablesMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-table-large menu-icon"></i>
              <span className="menu-title"><Trans>Tables</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ menuState.tablesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ isPathActive('/tables/basic-table') ? 'nav-link active' : 'nav-link' } to="/tables/basic-table"><Trans>Basic Table</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ isPathActive('/icons') ? 'nav-item active' : 'nav-item' }>
            <div className={ menuState.iconsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('iconsMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-account-box-outline menu-icon"></i>
              <span className="menu-title"><Trans>Icons</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ menuState.iconsMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ isPathActive('/icons/mdi') ? 'nav-link active' : 'nav-link' } to="/icons/mdi">Material</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ isPathActive('/charts') ? 'nav-item active' : 'nav-item' }>
            <div className={ menuState.chartsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('chartsMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-chart-line menu-icon"></i>
              <span className="menu-title"><Trans>Charts</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ menuState.chartsMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ isPathActive('/charts/chart-js') ? 'nav-link active' : 'nav-link' } to="/charts/chart-js">Chart Js</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ isPathActive('/user-pages') ? 'nav-item active' : 'nav-item' }>
            <div className={ menuState.userPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('userPagesMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-lock-outline menu-icon"></i>
              <span className="menu-title"><Trans>User Pages</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ menuState.userPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ isPathActive('/user-pages/login') ? 'nav-link active' : 'nav-link' } to="/user-pages/login"><Trans>Login</Trans></Link></li>
                <li className="nav-item"> <Link className={ isPathActive('/user-pages/register') ? 'nav-link active' : 'nav-link' } to="/user-pages/register"><Trans>Register</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ isPathActive('/error-pages') ? 'nav-item active' : 'nav-item' }>
            <div className={ menuState.errorPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('errorPagesMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-information-outline menu-icon"></i>
              <span className="menu-title"><Trans>Error Pages</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ menuState.errorPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ isPathActive('/error-pages/error-404') ? 'nav-link active' : 'nav-link' } to="/error-pages/error-404">404</Link></li>
                <li className="nav-item"> <Link className={ isPathActive('/error-pages/error-500') ? 'nav-link active' : 'nav-link' } to="/error-pages/error-500">500</Link></li>
              </ul>
            </Collapse>
          </li>
          
          <li className="nav-item">
            <a className="nav-link" href="http://www.bootstrapdash.com/demo/star-admin-free/react/documentation/documentation.html" rel="noopener noreferrer" target="_blank">
              <i className="mdi mdi-file-outline menu-icon"></i>
              <span className="menu-title"><Trans>Documentation</Trans></span>
            </a>
        </li>
           */}
           
          
          


          
        </ul>
      </nav>
    );
}

  
export default withRouter(Sidebar);