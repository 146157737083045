import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { withRouter , Link} from 'react-router-dom';
import { Trans } from 'react-i18next';
import { signOutUser, getCurrentProfilePicture } from '../services/authServices';

const Navbar = ({history, photoURL, toggleNavBar}) => {

  const toggleOffcanvas = () => {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  const toggleRightSidebar = () => {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }

  const handleSignOut = async (evt) => {
    evt.preventDefault()
    const response = await signOutUser()
    localStorage.clear();
    if (response.success){
      history.push('user-pages/login')
    }
  }

  const handleGoToSyndeoWebsite = (evt) => {
    evt.preventDefault()
    window.open('https://syndeo.cloud', '_blank');
  }

  const handleMyAccount = async (evt) => {
    evt.preventDefault();
    history.push('my-account');
  }

  const goToWelcomePage = async (evt) => {
    evt.preventDefault();
    history.push('welcome-page');
  }

  return (
      <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
        <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
        <a className="navbar-brand brand-logo-mini align-self-center d-lg-none nav-bar-syndeo-logo" href="!#" onClick={evt =>evt.preventDefault()}>
          <img src={require('../../assets/images/logo.png')} alt="logo" />
        </a>
          <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={toggleNavBar}>
            <i className="mdi mdi-menu"></i>
          </button>
          <ul className="navbar-nav navbar-nav-left header-links align-self-center">
            <li className="nav-item dropdown language-dropdown">
            <Dropdown>
                <Dropdown.Toggle className="nav-link count-indicator p-0 toggle-arrow-hide bg-transparent">
                  <div className="d-inline-flex mr-0 mr-md-3">
                    <div className="flag-icon-holder">
                      <i className="flag-icon flag-icon-us"></i>
                    </div>
                  </div>
                  <span className="profile-text font-weight-medium d-none d-md-block">English</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="navbar-dropdown preview-list">
                  <Dropdown.Item className="dropdown-item  d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                    <div className="flag-icon-holder">
                      <i className="flag-icon flag-icon-us"></i>
                    </div>English
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  {/* <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                    <div className="flag-icon-holder">
                      <i className="flag-icon flag-icon-fr"></i>
                    </div>French
                  </Dropdown.Item> 
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                    <div className="flag-icon-holder">
                      <i className="flag-icon flag-icon-ae"></i>
                    </div>Arabic
                   </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                    <div className="flag-icon-holder">
                      <i className="flag-icon flag-icon-ru"></i>
                    </div>Russian
                  </Dropdown.Item>  */}
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
         
          {/* <span className="general-app-title"><Trans>Syndeo</Trans></span>         */}

          {/* <form className="ml-auto search-form d-none d-md-block" action="#">
            <div className="form-group">
              <input type="search" className="form-control" placeholder="Search Here" />
            </div>
          </form> */}
          <ul className="navbar-nav navbar-nav-right ml-auto">    
            <li className="nav-item">
              <button type="button"  className="btn btn-social-icon tooltip-button-new-notification welcome-page-help-button"
                onClick={goToWelcomePage}
              >
                <i className="mdi mdi-help-circle-outline "></i>
              </button>  
            </li>    
            <li className="nav-item  nav-profile border-0">
              <Dropdown>
                <Dropdown.Toggle className="nav-link count-indicator bg-transparent">
                  {photoURL != undefined && 
                      <img className="img-xs rounded-circle profile-picture nav-profile-picture" src={photoURL} alt="Profile" />
                  }
                  {photoURL == undefined && 
                    <img className="img-xs rounded-circle profile-picture nav-profile-picture" src={require('../../assets/images/default_profile.jpeg')} alt="Profile" />
                  }

                </Dropdown.Toggle>
                <Dropdown.Menu className="preview-list navbar-dropdown pb-3">
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0 mt-2 account-dropdown-element" onClick={(evt) => handleMyAccount(evt)}>
                      <Trans>My Account</Trans>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0 account-dropdown-element" onClick={(evt) => handleGoToSyndeoWebsite(evt)}>
                      <Trans>Syndeo Website</Trans>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0 account-dropdown-element red" onClick={(evt) => handleSignOut(evt)}>
                    <Trans>Sign Out</Trans>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
    );
}
export default withRouter(Navbar)